import { Config } from './typing';

export const config: Config = {
  basename: '/',
  apiRoot: '',
  oauth: {
    authority: 'https://auth.optocycle.net/realms/optocycle/',
    clientId: 'dsm.optocycle.net',
  },
};
